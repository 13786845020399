import api from "../axios_service.js";

export async function fetchAtividadesClientes(filtros) {
  const response = await api.get(
    `/gestor/atividades-clientes${filtros ? filtros : ""}`
  );
  return response.data.result;
}

export async function fetchAtividadesClientesCliente(filtros) {
  const response = await api.get(
    `/gestor/atividades-clientes-cliente${filtros ? filtros : ""}`
  );
  return response.data.result;
}
export async function fetchAtividadesClientesHistoricos(atividade_id) {
  const response = await api.get(
    `/gestor/atividades-clientes-historico/${atividade_id}`
  );
  return response.data.result;
}

// Busca um Briefing do Clientes
export async function fetchAtividadeCliente(id) {
  const response = await api.get(`/gestor/atividades-clientes/${id}`);
  return response.data.result;
}

// Atualiza um Briefing do Clientes
export function putAtividadeCliente(id, atividade) {
  let body = {};
  for (let key in atividade) {
    body[key] = atividade[key];
  }
  return api.put(`/gestor/atividades-clientes/${id}`, body);
}

// editar tmepo
export function putAtividadeEditarTempo(id, tempo) {
  return api.put(`/gestor/atividades-clientes-editar-tempo/${id}`, tempo);
}

export function deleteAtividadeTempo(id) {
  return api.delete(`/gestor/atividades-clientes-tempo/${id}`);
}

export async function postAtividadeAdicionarTempo(tempo) {
  const response = await api.post(
    "/gestor/atividades-clientes-adicionar-tempo/add",
    tempo
  );
  return response;
}
// Cria um cliente novo
export async function postAtividadeCliente(atividade) {
  const response = await api.post("/gestor/atividades-clientes/add", atividade);
  return response;
}

export async function fetchAtividade(id) {
  const response = await api.get(`/gestor/atividade/${id}`);
  return response.data.result;
}
